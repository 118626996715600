.brand-detail {
    .img-container {
        position: relative;
        //margin-bottom: 130px;
        
        .cover-photo {
            width: 100%;
            height: auto;
            max-height: 400px;
            object-fit: cover;
            border-radius: 0.42rem 0.42rem 0 0;
            
        }
        
        .profile-photo {
            border-radius: 50%;
            border: 2px solid #fff;
            position: absolute;
            left: 30px;
            top: calc(100% - 130px);
            width: 260px;
            height: 260px;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
            overflow: visible;
            z-index: 99;
        }
    }
    
    .info-container {
        font-size: 15px;
        
        .category-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 1rem;
            
            .category-tag {
                color: #fff;
                margin-bottom: 0.5rem;
                background-color: #6c757d;
                
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }
    
    .brand-info-text {
        display: flex;
        
        span {
            &:first-child {
                width: 30%;
            }
            
            &:last-child {
                width: 70%;
            }
        }
    }
    
    .brand-social {
        display: flex;
        justify-content: start;
        
        a {
            background-color: #fff;
            transition: all 0.4s ease;
            border-radius: 50%;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
            
            i {
                width: 35px;
                height: 35px;
                font-size: 18px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            &:not(:last-child) {
                margin-right: 0.75rem;
            }
            
            &:hover,
            &:focus,
            &:active {
                background-color: rgba(0, 0, 0, 0.1);
                text-decoration: none;
            }
        }
    }
    
}
