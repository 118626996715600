.attached-pictures {
    display: flex;
    flex-wrap: wrap;
    
    > div {
        position: relative;
        padding-bottom: 1rem;
        width: calc(20% - 1rem);
        height: 200px;
        margin-right: 1rem;
        
        img {
            height: 100%;
            object-fit: cover;
        }
        
        a {
            position: absolute;
            top: 0.5rem;
            right: 1rem;
        }
        
        .fa-trash {
            background-color: rgba(242, 102, 87, 0.5);
            color: #fff;
            left: 0.5rem;
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
}

// Fileuploader override

.fileuploader-theme-thumbnails {
    .fileuploader-item {
        .actions-holder {
            top: 0.5rem;
            right: 1rem;
        }
    }
    
    .fileuploader-action-remove {
        i {
            background-color: rgba(242, 102, 87, 0.5) !important;
            height: 28px !important;
            width: 28px !important;
            box-shadow: none !important;
            border-radius: 50%;
            
            &:after {
                content: "\f1f8" !important;
                font-family: "Font Awesome 5 Free";
                font-size: 1.25rem;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 28px;
            }
        }
    }
}

.custom-file {
    height: auto !important;
}

.fileuploader-item {
    width: calc(20% - 1rem) !important;
}

.fileuploader-item-inner {
    height: 200px;
}

.fileuploader-items-list {
    background-color: #fff !important;
}

.fileuploader-thumbnails-input {
    position: relative !important;
    display: inline-block !important;
    margin: 1rem 0 0 1rem !important;
    vertical-align: top !important;
    width: calc(20% - 1rem) !important;
    padding-top: 20% !important;
    height: 200px;
}

.fileuploader-thumbnails-input-inner {
    height: 100% !important;
    
    i {
        font-size: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .attached-pictures > div {
        width: calc(33.3333% - 1rem) !important;
    }
    
    .attached-pictures > div,
    .fileuploader-item {
        height: 170px !important;
    }
    
    .fileuploader-thumbnails-input {
        height: 210px;
    }
    
    .fileuploader-items-list {
        li {
            width: calc(32% - 1rem) !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .attached-pictures > div {
        width: calc(50% - 1rem) !important;
    }
    
    .attached-pictures > div,
    .fileuploader-thumbnails-input,
    .fileuploader-item {
        height: 170px !important;
    }
    
    .fileuploader-items-list {
        li {
            width: calc(48% - 1rem) !important;
        }
    }
}
