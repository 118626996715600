.field-80-20 {
    display: flex;
    
    .form-group {
        &:first-child {
            width: calc(80% - 0.5rem);
            margin-right: 0.5rem;
        }
        
        &:last-child {
            width: calc(20% - 0.5rem);
            margin-left: 0.5rem;
        }
    }
}

.field-50-50 {
    display: flex;
    justify-content: space-between;
    
    .form-group {
        width: calc(50% - 0.5rem);
        
        &:first-child {
            margin-right: 0.5rem;
        }
        
        &:last-child {
            margin-left: 0.5rem;
        }
    }
}

.field-70-30 {
    display: flex;
    
    .form-group {
        &:first-child {
            width: calc(70% - 0.5rem);
            margin-right: 0.5rem;
        }
        
        &:last-child {
            width: calc(30% - 0.5rem);
            margin-left: 0.5rem;
        }
    }
}

.col-form-label {
    padding-top: 0;
}
