.aside-menu {
    .menu-item {
        > .menu-link {
            
            .menu-icon {
                font-size: 20px !important;
            }
            
            &.active {
                background-color: #F3F6F9;
                
                .menu-text,
                .menu-icon {
                    color: $primary;
                }
            }
        }
    }
}

