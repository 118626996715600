.top_brand_form {
    
    .vich-image {
        margin: 0 auto;
    }
    
    .dropify-wrapper {
        z-index: 100;
        font-size: 14px !important;
    }
    
    .vich-image a img {
        width: 100% !important;
        height: 300px !important;
    }
    
    .dropify-message p {
        font-size: 18px !important;
    }
    
}


.select2-container.form-control {
    height: auto !important;
}

.select2-selection {
    border: 2px solid #dde2ec !important;
    border-radius: 4px !important;
    color: #495057 !important;
    min-height: 38px !important;
    height: calc(calc(1.5em + 0.75rem) + 4px) !important;
    display: flex !important;
    align-items: center !important;
}

.form-group {
    .select2-selection,
    .select2-selection--multiple {
        border: 1px solid #E5EAEE !important;
    }
}

.dropify-wrapper {
    z-index: 100;
}

.custom-file-label {
    z-index: -100;
    display: none;
}

#demo{
    color: white;
    background-color: red;
    padding: 6px;
    border-radius: 5px;
    font-size: 12px;
}
