.top_brand_form .vich-image {
  margin: 0 auto;
}

.top_brand_form .dropify-wrapper {
  z-index: 100;
  font-size: 14px !important;
}

.top_brand_form .vich-image a img {
  width: 100% !important;
  height: 300px !important;
}

.top_brand_form .dropify-message p {
  font-size: 18px !important;
}

.select2-container.form-control {
  height: auto !important;
}

.select2-selection {
  border: 2px solid #dde2ec !important;
  border-radius: 4px !important;
  color: #495057 !important;
  min-height: 38px !important;
  height: calc(calc(1.5em + 0.75rem) + 4px) !important;
  display: flex !important;
  align-items: center !important;
}

.form-group .select2-selection,
.form-group .select2-selection--multiple {
  border: 1px solid #E5EAEE !important;
}

.dropify-wrapper {
  z-index: 100;
}

.custom-file-label {
  z-index: -100;
  display: none;
}

#demo {
  color: white;
  background-color: red;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
}

.attached-pictures {
  display: flex;
  flex-wrap: wrap;
}

.attached-pictures > div {
  position: relative;
  padding-bottom: 1rem;
  width: calc(20% - 1rem);
  height: 200px;
  margin-right: 1rem;
}

.attached-pictures > div img {
  height: 100%;
  object-fit: cover;
}

.attached-pictures > div a {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}

.attached-pictures > div .fa-trash {
  background-color: rgba(242, 102, 87, 0.5);
  color: #fff;
  left: 0.5rem;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.fileuploader-theme-thumbnails .fileuploader-item .actions-holder {
  top: 0.5rem;
  right: 1rem;
}

.fileuploader-theme-thumbnails .fileuploader-action-remove i {
  background-color: rgba(242, 102, 87, 0.5) !important;
  height: 28px !important;
  width: 28px !important;
  box-shadow: none !important;
  border-radius: 50%;
}

.fileuploader-theme-thumbnails .fileuploader-action-remove i:after {
  content: "\f1f8" !important;
  font-family: "Font Awesome 5 Free";
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 28px;
}

.custom-file {
  height: auto !important;
}

.fileuploader-item {
  width: calc(20% - 1rem) !important;
}

.fileuploader-item-inner {
  height: 200px;
}

.fileuploader-items-list {
  background-color: #fff !important;
}

.fileuploader-thumbnails-input {
  position: relative !important;
  display: inline-block !important;
  margin: 1rem 0 0 1rem !important;
  vertical-align: top !important;
  width: calc(20% - 1rem) !important;
  padding-top: 20% !important;
  height: 200px;
}

.fileuploader-thumbnails-input-inner {
  height: 100% !important;
}

.fileuploader-thumbnails-input-inner i {
  font-size: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .attached-pictures > div {
    width: calc(33.3333% - 1rem) !important;
  }
  .attached-pictures > div,
  .fileuploader-item {
    height: 170px !important;
  }
  .fileuploader-thumbnails-input {
    height: 210px;
  }
  .fileuploader-items-list li {
    width: calc(32% - 1rem) !important;
  }
}

@media only screen and (max-width: 767px) {
  .attached-pictures > div {
    width: calc(50% - 1rem) !important;
  }
  .attached-pictures > div,
  .fileuploader-thumbnails-input,
  .fileuploader-item {
    height: 170px !important;
  }
  .fileuploader-items-list li {
    width: calc(48% - 1rem) !important;
  }
}

.pagination .page-item a,
.pagination .next a,
.pagination .prev a {
  color: inherit;
  border-radius: 25px;
}

.pagination .page-item a:hover,
.pagination .next a:hover,
.pagination .prev a:hover {
  text-decoration: none;
}

.pagination .page-item {
  padding: 0 0.5rem;
  color: #5A5A5A;
  font-weight: 600;
}

.pagination .page-item a {
  padding: 3px 15px;
}

.pagination .page-item.active {
  color: #333333;
}

.pagination .page-item.active a {
  border: 1px solid #bdbdbd;
}

.pagination .page-item:hover {
  color: #333333;
}

.pagination .prev,
.pagination .next {
  text-transform: uppercase;
  color: #333333;
  font-weight: 600;
}

.pagination .prev a,
.pagination .next a {
  padding: 3px 20px;
  border: 1px solid #bdbdbd;
}

.pagination .prev:hover,
.pagination .next:hover {
  opacity: 0.7;
}

.pagination .prev {
  padding-right: 10px;
}

.pagination .next {
  padding-left: 10px;
}

.aside-menu .menu-item > .menu-link .menu-icon {
  font-size: 20px !important;
}

.aside-menu .menu-item > .menu-link.active {
  background-color: #F3F6F9;
}

.aside-menu .menu-item > .menu-link.active .menu-text,
.aside-menu .menu-item > .menu-link.active .menu-icon {
  color: #333;
}

.field-80-20 {
  display: flex;
}

.field-80-20 .form-group:first-child {
  width: calc(80% - 0.5rem);
  margin-right: 0.5rem;
}

.field-80-20 .form-group:last-child {
  width: calc(20% - 0.5rem);
  margin-left: 0.5rem;
}

.field-50-50 {
  display: flex;
  justify-content: space-between;
}

.field-50-50 .form-group {
  width: calc(50% - 0.5rem);
}

.field-50-50 .form-group:first-child {
  margin-right: 0.5rem;
}

.field-50-50 .form-group:last-child {
  margin-left: 0.5rem;
}

.field-70-30 {
  display: flex;
}

.field-70-30 .form-group:first-child {
  width: calc(70% - 0.5rem);
  margin-right: 0.5rem;
}

.field-70-30 .form-group:last-child {
  width: calc(30% - 0.5rem);
  margin-left: 0.5rem;
}

.col-form-label {
  padding-top: 0;
}

.brand-detail .img-container {
  position: relative;
}

.brand-detail .img-container .cover-photo {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
  border-radius: 0.42rem 0.42rem 0 0;
}

.brand-detail .img-container .profile-photo {
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  left: 30px;
  top: calc(100% - 130px);
  width: 260px;
  height: 260px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  overflow: visible;
  z-index: 99;
}

.brand-detail .info-container {
  font-size: 15px;
}

.brand-detail .info-container .category-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.brand-detail .info-container .category-container .category-tag {
  color: #fff;
  margin-bottom: 0.5rem;
  background-color: #6c757d;
}

.brand-detail .info-container .category-container .category-tag:not(:last-child) {
  margin-right: 1rem;
}

.brand-detail .brand-info-text {
  display: flex;
}

.brand-detail .brand-info-text span:first-child {
  width: 30%;
}

.brand-detail .brand-info-text span:last-child {
  width: 70%;
}

.brand-detail .brand-social {
  display: flex;
  justify-content: start;
}

.brand-detail .brand-social a {
  background-color: #fff;
  transition: all 0.4s ease;
  border-radius: 50%;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}

.brand-detail .brand-social a i {
  width: 35px;
  height: 35px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-detail .brand-social a:not(:last-child) {
  margin-right: 0.75rem;
}

.brand-detail .brand-social a:hover, .brand-detail .brand-social a:focus, .brand-detail .brand-social a:active {
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
