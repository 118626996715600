.pagination {
    .page-item a,
    .next a,
    .prev a {
        color: inherit;
        border-radius: 25px;
        
        &:hover {
            text-decoration: none;
        }
    }
    
    .page-item {
        padding: 0 0.5rem;
        color: #5A5A5A;
        font-weight: 600;
        
        a{
            padding: 3px 15px;
        }
        
        &.active {
            color: #333333;
            
            a {
                border: 1px solid #bdbdbd;
            }
        }
        
        &:hover {
            color: #333333;
        }
    }
    
    .prev,
    .next {
        text-transform: uppercase;
        color: #333333;
        font-weight: 600;
        
        a{
            padding: 3px 20px;
            border: 1px solid #bdbdbd;
        }
        
        
        &:hover {
            opacity: 0.7;
        }
    }
    
    .prev {
        padding-right: 10px;
    }
    
    .next {
        padding-left: 10px;
    }
}
